<template>
  <DataTable ref="dt" :value="ndfs" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber" selection-mode="single"
             dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" :loading="loading" responsiveLayout="scroll"
             paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
             :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.data')})"
             sort-field="independentStatus" :sort-order="-1" @rowSelect="clickNdf">
    <template #header>
      <div class="p-d-flex flex">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
        </span>
      </div>
    </template>
    <template #empty>
      {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
    </template>
    <template #loading>
      {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
    </template>
    <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable filterField="independent.last_name" sortField="independent.last_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
      <template #filter="{filterModel, filterCallback}">
        <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
        <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="lastnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
          <template #option="slotProps">
            <div>
              <span>{{slotProps.option.value}}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable filterField="independent.first_name" sortField="independent.first_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
      <template #filter="{filterModel, filterCallback}">
        <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
        <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="firstnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
          <template #option="slotProps">
            <div>
              <span>{{slotProps.option.value}}</span>
            </div>
          </template>
        </MultiSelect>
      </template>
    </Column>
    <Column field="independent.cagnotte" :header="$t('cagnotte').toUpperCase()" sortable sortField="independent.cagnotte">
      <template #body="{data}">
        <span class="font-bold" :style="data.independent.cagnotte >= 0 ? 'color: green' : 'color: red'">{{ getEuroFormatFromEuro(data.independent.cagnotte / 100) }}</span>
      </template>
    </Column>
    <Column field="date" :header="$t('date').toUpperCase()" sortable filterField="date" sortField="date" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
      <template #body="{data}">
        {{getDateFormatted(data.date, {month: '2-digit', year: 'numeric',})}}
      </template>
      <template #filter="{filterModel, filterCallback}">
        <div class="p-mb-3 p-text-bold mb-3">{{ $t('date') }}</div>
        <Calendar @date-select="filterCallback()" v-model="filterModel.value" view="month" dateFormat="mm/yy" placeholder="dd/yyyy" />
      </template>
    </Column>
    <Column field="type" :header="$t('libelle').toUpperCase()" sortable filterField="type" sortField="type" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
      <template #body="{data, field}">
        {{ getOptionLabel(constants.data.ndfTypeOptions, data[field]) }}
      </template>
      <template #filter="{filterModel, filterCallback}">
        <div class="p-mb-3 p-text-bold mb-3">{{ $t('libelle') }}</div>
        <MultiSelect v-model="filterModel.value" :options="libellesFilter" optionValue="value" optionLabel="label"
                     :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem"
                     @change="filterCallback()"/>
      </template>
    </Column>
    <Column field="ttc" :header="$t('montant').toUpperCase()" sortable sortField="ttc">
      <template #body="{data}">
        {{ getEuroFormat(data.ttc) }}
      </template>
    </Column>
    <Column field="independentStatus" header="STATUT" sortable sortField="independentStatus">
      <template #body="{data}">
        {{ getOptionLabel(constants.data.ndfOptions, data.independentStatus) }}
      </template>
    </Column>
  </DataTable>
  <dialog-n-d-f-admin :key="keyDialog" :dialog="dialogNdf" :ndf="selectedNdf" @close="dialogNdf = false" @reload="$emit('reload')"/>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import * as Money from "@/utils/Money";
import DialogNDFAdmin from "@/components/NDF/DialogNDFAdmin";
import {getDateFormatted} from "@/utils/Date";
import {FilterMatchModeCustom} from "@/utils/FiltersRegistration";
import {mapState} from "vuex";
import {getOptionLabel} from "@/utils/Misc";
import {getEuroFormatFromEuro} from "@/utils/Money";

export default {
  name: "TableauNDFAdmin",
  emits: ["reload"],
  props: ['ndfs'],
  components: {DialogNDFAdmin},
  data () {
    return {
      filters: {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'type': {value: null, matchMode: FilterMatchMode.IN},
        'date': {value: null, matchMode: FilterMatchModeCustom.DATE_AFTER_INCLUDED}
      },
      getEuroFormat: Money.getEuroFormat,
      loading: false,
      exportSheetLoading: false,
      selectedNdf: null,
      dialogNdf: false,
      keyDialog: 0
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    libellesFilter () {
      let array = this.ndfs.map((p) => {
        return {
          label: getOptionLabel(this.constants.data.ndfTypeOptions, p.type),
          value: p.type
        }
      })
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item.value)) {
          filterArray.push(item)
        }
      })
      return filterArray
    },
    firstnamesFilter () {
      let array = this.ndfs.map((p) => p.independent.first_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    lastnamesFilter () {
      let array = this.ndfs.map((p) => p.independent.last_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    }
  },
  methods: {
    getEuroFormatFromEuro,
    getOptionLabel,
    getDateFormatted,
    clickNdf (ndf) {
      this.selectedNdf = ndf.data
      this.dialogNdf = true
      this.keyDialog++
    },
    pushTo (name) {
      this.$router.push({'name': name})
    }
  }
}
</script>

<style scoped>

</style>


