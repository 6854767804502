<template>
  <div class="flex-container non-scrollable">
    <div class="scrollable">
      <div v-if="ndfs" class="pr-3">
        <page-title value="Notes de frais à valider"/>
        <tableau-n-d-f-admin :ndfs="ndfs.filter(n => n.type !== constants.NDF_TYPE.SOCIAL_MEASURES)" @reload="loadData"/>
        <page-title value="Dispositifs sociaux à valider" class="mt-4"/>
        <tableau-n-d-f-admin :ndfs="ndfs.filter(n => n.type === constants.NDF_TYPE.SOCIAL_MEASURES)" @reload="loadData"/>
      </div>
      <div class="spacer"/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import TableauNDFAdmin from "@/components/NDF/TableauNDFAdmin";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "NDFAValider",
  components: {PageTitle, TableauNDFAdmin},
  data () {
    return {
      ndfs: null
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData () {
      this.$store.state.misc.loading = true
      this.ndfs = await this.$store.dispatch('ndf/getNdfToValidate')
      this.$store.state.misc.loading = false
    }
  }
}
</script>

<style scoped>

</style>
